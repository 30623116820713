import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const TermConditionsPage = () => {
  return (
    <Layout>
      <SEO
        title="Term and Conditions"
        description="...."
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container py-4">
        <div className="row py-5">
          <div className="col">
            <h1 className="w-100 text-center">TERM & CONDITIONS</h1>
          </div>
        </div>
        <div className="row flex-column shadow rounded py-4 mb-4">
          <div className="col">
            <h2>GENERAL TERMS OF SALE</h2>
            <p>
              Client agrees to purchase the above products (“Products”) subject to the following
              terms:{' '}
            </p>
            <ul className="numbered-list">
              <li>
                All sales are final and payments are nonrefundable. This document is
                nontransferable.
              </li>
              <li>
                The Total Due must be paid in full before Products are shipped. Credit Card payments
                are subject to 3% surcharge.
              </li>
              <li>
                See separate Product & Service Agreement for General Warranty information. Except
                for General Warranty, all Products are provided AS-IS.
              </li>
              <li>
                Products are shipped FOB point of origin. Client is responsible for
                <ul className="upper-roman">
                  <li>Inspecting products when delivered to ensure there is no visible damage </li>
                  <li>
                    Notifying the freight or shipping company of damages incurred during shipment
                    from Contégo facilities to the Client’s facilities
                  </li>
                </ul>
              </li>
              <li>
                The Contégo Jet-LinerTM is a one-time use, disposable product. It must be disposed
                of immediately after each use to ensure prevention of any possible
                cross-contamination and infection. Failure to comply voids the General Warranty.
              </li>
              <li>
                Product maintenance is required, per “maintenance” section of user manual accessible
                via www.contegospa.com/downloads.
              </li>
              <li>
                The above stated or referenced terms comprise the entire statement of agreement
                between Client and Contego relating to its subject matter and may not be changed
                without the written consent of both parties.
              </li>
            </ul>
          </div>
        </div>

        <div className="row flex-column shadow rounded py-4 mb-4">
          <div className="col">
            <h2>LIMITED WARRANTY</h2>
            <p>
              CONTÉGO Spa Designs Inc., has designed and manufactured the Novo™ Pedicure Spa to the
              highest standards. Accordingly, CONTÉGO Spa Designs Inc., expressly warrants the Novo™
              Pedicure Spa as follows:
            </p>
            <p>
              To the original owner of the Novo™ Pedicure Spa, CONTÉGO Spa Designs Inc., may repair
              or replace, at its option, any Novo™ Pedicure Spa found to be defective in material or
              workmanship upon inspection by a factory authorized representative of CONTÉGO Spa
              Designs Inc., This Limited Warranty covers the following:
            </p>
            <ul className="numbered-list">
              <li>
                <strong>Air Pumps</strong> – Twenty Four (24) months from date of shipment from
                CONTÉGO facility.
              </li>
              <li>Basin – Twelve (12) months from date of shipment from CONTÉGO facility.</li>
              <li>
                Other Components/Parts - Twelve (12) months from shipment from CONTÉGO facility
              </li>
              <li>Accessories - Twelve (12) months from shipment from CONTÉGO facility</li>
              <li>
                Shipping Costs - Twelve (12) months from the date of shipment CONTÉGO will cover
                normal shipping costs associated with replacement parts. Expedited shipping costs
                will incur an additional charge.
              </li>
            </ul>
            <br />
            <p>
              <strong>
                NO CONSEQUENTIAL, INCIDENTAL, CONTINGENT, INDIRECT, SPECIAL OR OTHER DAMAGES WILL BE
                ALLOWED WHATSOEVER
              </strong>
              . This limited warranty is null and void if the Novo™ is removed from it’s original
              installation, or is not installed in accordance with specifications of CONTÉGO Spa
              Designs Inc., or national, state or local codes, regulations and ordinances.
              Furthermore, this limited warranty is null and void if the Novo™ Pedicure Spa is
              subjected to improper installation or maintenance, negligence, improper cleaning or
              any other circumstances beyond CONTÉGO Spa Designs Inc., control. In order for your
              Limited Warranty to be valid, your Novo™ Pedicure Spa must be installed by a licensed
              professional in accordance with CONTÉGO Spa Designs Inc., installation instructions.
            </p>
            <p>
              <strong>EXCLUDED FROM LIMITED WARRANTY:</strong>
              <ul className="numbered-list">
                <li>Unauthorized repairs.</li>
                <li>Damage due to freight, accident, natural disaster and/or abuse.</li>
                <li>
                  Staining, fading or discolorization of the seat, seat back, armrests or pivoting
                  leg supports.
                </li>
                <li>
                  Damage or malfunctions due to improper installation including but not limited to,
                  failure to comply with all national, state and local plumbing, electrical and
                  building codes.
                </li>
                <li>
                  Fading, discoloration or staining of any part due to improper water additives or
                  harmful cleaning agents or chemicals.
                </li>
                <li>Damage to drain pump due to obstruction in drain hose.</li>
              </ul>
            </p>
            <div className="d-flex flex-column">
              <p>
                This limited warranty is to the original owner and is not transferable. The original
                owner agrees by using the Novo™ Pedicure Spa the obligation of CONTÉGO Spa Designs
                Inc., shall not extend to indirect or contingent damages or injury. Furthermore, the
                obligations of CONTÉGO Spa Designs Inc., are limited to those set forth herein. This
                limited warranty is the exclusive remedy and is in lieu of all other warranties,
                express or limited, including, but not limited to, any implied warranty of fitness
                or merchantability for a particular purpose. No distributor, dealer or other person
                has the right or authority to bind CONTÉGO Spa Designs Inc., to changes to this
                limited warranty. Consequently, CONTÉGO Spa Designs Inc., is not responsible for
                such warranties or representations.
              </p>
              <p>
                <strong>ADDITIONAL PROVISIONS</strong>
                <ul className="numbered-list">
                  <li>
                    Any assistance CONTÉGO Spa Designs Inc., provides to the original owner above
                    and beyond the scope of this limited warranty does not constitute a waiver of
                    such terms, limitations or exclusions. Furthermore, such assistance does not
                    extend this limited warranty.
                  </li>
                  <li>
                    CONTÉGO Spa Designs Inc., shall not be liable for any other obligations or
                    liabilities as a result of breach of contract of warranty, negligence or strict
                    liability with regards to the Novo™ Pedicure Spa.
                  </li>
                  <li>
                    This Limited Warranty applies only to the Novo™ Pedicure Spas installed in any
                    one of the fifty states of the{' '}
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermConditionsPage
